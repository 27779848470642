// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-buy-now-tsx": () => import("./../../../src/pages/buy-now.tsx" /* webpackChunkName: "component---src-pages-buy-now-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-find-tsx": () => import("./../../../src/pages/find.tsx" /* webpackChunkName: "component---src-pages-find-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quickcalm-tsx": () => import("./../../../src/pages/quickcalm.tsx" /* webpackChunkName: "component---src-pages-quickcalm-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blog-post-template.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-collections-tsx": () => import("./../../../src/templates/collections.tsx" /* webpackChunkName: "component---src-templates-collections-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/genericPage.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-learn-page-tsx": () => import("./../../../src/templates/learnPage.tsx" /* webpackChunkName: "component---src-templates-learn-page-tsx" */),
  "component---src-templates-listen-page-tsx": () => import("./../../../src/templates/listenPage.tsx" /* webpackChunkName: "component---src-templates-listen-page-tsx" */),
  "component---src-templates-press-post-template-tsx": () => import("./../../../src/templates/press-post-template.tsx" /* webpackChunkName: "component---src-templates-press-post-template-tsx" */),
  "component---src-templates-press-releases-tsx": () => import("./../../../src/templates/pressReleases.tsx" /* webpackChunkName: "component---src-templates-press-releases-tsx" */),
  "component---src-templates-product-page-index-tsx": () => import("./../../../src/templates/productPage/index.tsx" /* webpackChunkName: "component---src-templates-product-page-index-tsx" */),
  "component---src-templates-shop-all-tsx": () => import("./../../../src/templates/shop-all.tsx" /* webpackChunkName: "component---src-templates-shop-all-tsx" */),
  "component---src-templates-shop-category-index-tsx": () => import("./../../../src/templates/shopCategory/index.tsx" /* webpackChunkName: "component---src-templates-shop-category-index-tsx" */)
}

