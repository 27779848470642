import React from "react";
import { ChakraProvider, extendTheme, Box } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import "../../styles/styles.scss";
import Cookies from "universal-cookie";

/* IMPORT APP CONTEXT */
import { AppContext } from "../../context";

const breakpoints = createBreakpoints({
  base: "0px",
  sm: "320px",
  ssm: "360px",
  ms: "544px",
  msx: "655px",
  hhg: "720px",
  md: "768px",
  mds: "895px",
  lg: "1024px",
  lgm: "1100px",
  lgs: "1231px",
  xl: "1440px",
  xxl: "1648px",
  sl: "2104px",
  ssl: "2400px",
  xsl: "2700px",
  msl: "3000px",
  ml: "3500px",
  exl: "4000px",
});

const colors = {
  primary: "#272158",
  primaryDarker: "#2F2865",
  primaryLighter: "#312669",
  primaryMenu: "#3D3887",
  white: "#FFFFFF",
  menuGreen: "#7FC344",
  socialIcon: "#266f54",
  blue: "#01A7CF",
  blueDarker: "#00A9D3",
  honeyYellow: "#F9AF1C",
  red: "#EC2127",
  greenMantis: "#7FC344",
  grayBG: "#F1F0FA",
};

const textStyles = {
  "double-spaced": {
    letterSpacing: "3px",
  },
};

const theme = extendTheme({
  breakpoints,
  colors,
  textStyles,
  components: {
    Checkbox: {
      baseStyle: {
        icon: {
          color: "white",
        },
        control: {
          border: "1px",
          _focus: {
            boxShadow: "none",
          },
          borderColor: "primary",
          borderRadius: "2px",
          _checked: {
            bg: "primary",
          },
          _disabled: {
            borderColor: "primary",
            bg: "white",
          },
        },
        label: {
          fontSize: "10px",
          color: "primary",
        },
      },
    },
  },
});

/* COMPONENTS */
import Header from "../Header";
import HeaderStickyButtons from "../HeaderStickyButtons";
import Menu from "../Menu";
import Footer from "../Footer";
import AgeVerification from "../AgeVerification";
import BuyNowModal from "../BuyNowModal";

function Layout(props) {
  const [showMenu, setShowMenu] = React.useState(false);
  const appContext = React.useContext(AppContext);

  /* 21+ DISCLAIMER CONTROL */
  const cookies = new Cookies();

  const ageCookieKey = "wana_21_or_older";
  const [ageCookie, setAgeCookie] = React.useState(true);
  const [ageCookieExpiration, setAgeCookieExpiration] = React.useState(null);

  React.useEffect(() => {
    const currentCookieValue = cookies.get(ageCookieKey);
    // console.log('LAYOUT LOADED', props);
    setAgeCookie(currentCookieValue ? currentCookieValue : false);

    if (!currentCookieValue) {
      const maxDays = 7;
      const dateNow = new Date();
      const expiresIn = dateNow.setTime(
        dateNow.getTime() + maxDays * 60 * 60 * 24 * 1000
      );
      setAgeCookieExpiration(new Date(expiresIn));
    }
  }, []);

  const [containerClass, setContainerClass] = React.useState(
    "page-wrapper overflow-hidden"
  );
  const [isAShopPage, setIsAShopPage] = React.useState(false);

  React.useEffect(() => {
    if (
      props.children.key.includes("/collections/") &&
      props.children.key !== "/collections/"
    ) {
      setContainerClass("page-wrapper shop-page-overflow");
      setIsAShopPage(true);
    } else {
      setContainerClass("page-wrapper overflow-hidden");
      setIsAShopPage(false);
    }
  }, [props.children.key]);

  const isFiltersBarOpen = appContext.isFiltersBarOpen;

  return (
    <ChakraProvider theme={theme}>
      <Box
        className={containerClass}
        ref={appContext.pageTopRef}
        onClick={(event) => {
          if (
            showMenu &&
            event.target.id !== "menu-topContainer" &&
            event.target.id !== "menu-bottomContainer" &&
            event.target.id !== "menu-linksSetHeader"
          ) {
            // console.log('showMenu: ', event.target.id);
            setShowMenu(false);
          }
        }}
      >
        {/* PAGE */}
        {ageCookie && (
          <Box position="relative" id="page-container">
            {/* HEADER */}
            <Box
              width="100%"
              //position={!showMenu ? 'fixed' : 'fixed'} // comment for non fixed header
              //top="0" // comment for non fixed header
              zIndex="overlay"
              bgColor="white"
            >
              <Header showMenu={showMenu} setShowMenu={setShowMenu} />
            </Box>

            {/* PAGE CONTENT */}
            <Box
              position="relative"
              style={{ zIndex: 1 }}
              //top={{base:'53px', md:'76px', lg:'96px'}} // comment for non fixed header
            >
              {/* MENU */}
              <Box
                w="100%"
                h="auto"
                position="absolute"
                top="0"
                zIndex="4"
                //display="none" // comment for non fixed header
              >
                <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
              </Box>

              {props.children}
            </Box>
            {/* FOOTER */}
            <Footer />

            {/* SPACE */}
            <Box
              h={{
                base: "60px",
                ms: "70px",
                md: "7.5vw",
                lg: "6.95vw",
                xl: "100px",
                sl: "150px",
              }}
            />

            {/* HEADER STICKY BUTTONS */}
            <Box
              display={{ base: "block", md: "none" }}
              position="fixed"
              bottom="0"
              width="100%"
              zIndex={!showMenu ? "overlay" : "2"}
            >
              <HeaderStickyButtons />
            </Box>

            {/* BUY NOW MODAL */}
            <BuyNowModal />
          </Box>
        )}

        {/* AGE VERIFICATION */}
        {!ageCookie && (
          <AgeVerification
            ageCookie={ageCookie}
            setAgeCookie={() => {
              cookies.set(ageCookieKey, true, { expires: ageCookieExpiration });
              setAgeCookie(cookies.get(ageCookieKey));
            }}
          />
        )}
      </Box>
    </ChakraProvider>
  );
}

export default Layout;
