import * as React from 'react'
import Layout from './src/components/Layout'

/* CONTEXT PROVIDER */
import { Provider } from './src/context'

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.pathname.includes('collections') && location.pathname !== '/collections/') {
    return false
  }
}

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <Provider>
      <Layout>{element}</Layout>
    </Provider>
  )
}

export const onClientEntry = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = 'https://a.omappapi.com/app/js/api.min.js'
  script.dataset.user = 66051
  script.dataset.account = 74272
  document.head.appendChild(script)
}
