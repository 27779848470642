import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Stack } from '@chakra-ui/react';
import LinkWrapper from '../LinkWrapper';

function SocialMedia(props) {
  const { withBG, color, size, spacing, children, inMenu } = props;

  const data = useStaticQuery(graphql`
    query {
      allSanitySocialMedia {
        nodes {
          name
          slug {
            current
          }
          href
        }
      }
    }
  `);

  const socialMedia = data.allSanitySocialMedia.nodes;

  const getSocialElement = (socialName) => {
    try {
      switch (socialName) {
        case 'linkedin':
          return <LinkedInSVG withBG={withBG} color={color} size={size} />
        case 'twitter':
          return <TwitterSVG withBG={withBG} color={color} size={size} />
        case 'youtube':
          return <YoutubeSVG withBG={withBG} color={color} size={size} />
        case 'instagram':
          return <InstagramSVG withBG={withBG} color={color} size={size} />
        case 'facebook':
          return <FacebookSVG withBG={withBG} color={color} size={size} />
      }
      
    } catch (error) {
      // console.log('social media error: ', error);
      return <FacebookSVG withBG={withBG} color={color} size={size} />
    }
  };

  return (
    <Box>
      {children}

      {socialMedia && (
        <Stack
          direction="row"
          spacing={spacing || '5px'}
          justifyContent={inMenu ? 'flex-start' : 'center'}
          alignItems="center"
        >
          {socialMedia.map((social, indx) => {
            return (
              <LinkWrapper key={indx} href={social?.href}>
                <Box 
                  w={`${size * 1.5}px`}
                  h={`${size * 1.5}px`}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="2px"
                >
                  {getSocialElement(social?.slug.current)}
                </Box>
              </LinkWrapper>
            );
          })}
        </Stack>
      )}

    </Box>
  );
}

const TwitterSVG = ({ withBG, color, size }) => {
  if (withBG) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5c0 86.7-66 186.6-186.6 186.6c-37.2 0-71.7-10.8-100.7-29.4c5.3.6 10.4.8 15.8.8c30.7 0 58.9-10.4 81.4-28c-28.8-.6-53-19.5-61.3-45.5c10.1 1.5 19.2 1.5 29.6-1.2c-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1c32.3 39.8 80.8 65.8 135.2 68.6c-9.3-44.5 24-80.6 64-80.6c18.9 0 35.9 7.9 47.9 20.7c14.8-2.8 29-8.3 41.6-15.8c-4.9 15.2-15.2 28-28.8 36.1c13.2-1.4 26-5.1 37.8-10.2c-8.9 13.1-20.1 24.7-32.9 34z" 
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
        <path 
          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645c0 138.72-105.583 298.558-298.558 298.558c-59.452 0-114.68-17.219-161.137-47.106c8.447.974 16.568 1.299 25.34 1.299c49.055 0 94.213-16.568 130.274-44.832c-46.132-.975-84.792-31.188-98.112-72.772c6.498.974 12.995 1.624 19.818 1.624c9.421 0 18.843-1.3 27.614-3.573c-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319c-28.264-18.843-46.781-51.005-46.781-87.391c0-19.492 5.197-37.36 14.294-52.954c51.655 63.675 129.3 105.258 216.365 109.807c-1.624-7.797-2.599-15.918-2.599-24.04c0-57.828 46.782-104.934 104.934-104.934c30.213 0 57.502 12.67 76.67 33.137c23.715-4.548 46.456-13.32 66.599-25.34c-7.798 24.366-24.366 44.833-46.132 57.827c21.117-2.273 41.584-8.122 60.426-16.243c-14.292 20.791-32.161 39.308-52.628 54.253z" 
          fill={color}
        />
      </svg>
    );
  }
};

const LinkedInSVG = ({ withBG, color, size }) => {
  if (withBG) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5c0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7c-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5c67.2 0 79.7 44.3 79.7 101.9V416z" 
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2c-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3c94 0 111.28 61.9 111.28 142.3V448z" 
          fill={color}
        />
      </svg>
    );
  }
};

const YoutubeSVG = ({ withBG, color, size }) => {
  if (withBG) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size * 1.5}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M186.8 202.1l95.2 54.1l-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4c-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9c7.6-28.5 7.6-88.1 7.6-88.1z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size * 1.5}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597c-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821c11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205l-142.739 81.201z"
          fill={color}
        />
      </svg>
    );
  }
};

const InstagramSVG = ({ withBG, color, size }) => {
  if (withBG) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M224 202.66A53.34 53.34 0 1 0 277.36 256A53.38 53.38 0 0 0 224 202.66zm124.71-41a54 54 0 0 0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31 6.43a54 54 0 0 0-30.41 30.41c-8.28 21-6.43 71.05-6.43 94.33s-1.85 73.27 6.47 94.34a54 54 0 0 0 30.41 30.41c21 8.29 71 6.43 94.31 6.43s73.24 1.93 94.3-6.43a54 54 0 0 0 30.41-30.41c8.35-21 6.43-71.05 6.43-94.33s1.92-73.26-6.43-94.33zM224 338a82 82 0 1 1 82-82a81.9 81.9 0 0 1-82 82zm85.38-148.3a19.14 19.14 0 1 1 19.13-19.14a19.1 19.1 0 0 1-19.09 19.18zM400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-17.12 290c-1.29 25.63-7.14 48.34-25.85 67s-41.4 24.63-67 25.85c-26.41 1.49-105.59 1.49-132 0c-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61 0-132c1.29-25.63 7.07-48.34 25.85-67s41.47-24.56 67-25.78c26.41-1.49 105.59-1.49 132 0c25.63 1.29 48.33 7.15 67 25.85s24.63 41.42 25.85 67.05c1.49 26.32 1.49 105.44 0 131.88z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9S287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7s74.7 33.5 74.7 74.7s-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8c-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8s26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9c-26.2-26.2-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9 0c-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9c1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2c26.2-26.2 34.4-58 36.2-93.9c2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6c-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6c29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6c11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
          fill={color}
        />
      </svg>
    );
  }
};

const FacebookSVG = ({ withBG, color, size }) => {
  if (withBG) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48c27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={`${size}px`} height={`${size}px`} preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
        <path 
          d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
          fill={color}
        />
      </svg>
    );
  }
};

export default SocialMedia;
