import * as React from "react";
import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import CTAButton from "../CTAButton";

/* IMPORT APP CONTEXT */
import { AppContext } from "../../context";

/* MODAL CONFIG */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    maxWidth: "466px",
    maxHeight: "max-content",
    padding: 0,
    border: "none",
    borderRadius: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: '-50%',
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
  },
};

Modal.setAppElement("#___gatsby");

function BuyNowOverlay(props) {
  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityShopWanaModal {
        nodes {
          img {
            asset {
              gatsbyImageData
            }
          }
          header
          boldText
          mainText
          medBtn {
            text
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              route
            }
          }
          recBtn {
            text
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              route
            }
          }
          findBtn {
            text
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              route
            }
          }
        }
      }
    }
  `);

  const shopWanaModalData = data.allSanityShopWanaModal.nodes[0];
  const appContext = React.useContext(AppContext);

  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const closeModal = () => {
    // document.body.style.overflowY = 'scroll';
    appContext.actions.buyNowModalControl();
    setModalIsOpen(false);
  };

  React.useEffect(() => {
    /* OPEN MODAL */
    if (appContext.isBuyNowModalOpen) {
      // document.body.style.overflow = 'hidden';
      setModalIsOpen(true);
    }
  }, [appContext.isBuyNowModalOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="shopWanaModal"
    >
      <Flex
        w={{ base: "100%", md: "466px" }}
        direction="column"
        alignItems="center"
        bgColor="white"
        mx="auto"
        overflow="hidden"
      >
        {/* IMAGE */}
        <Flex
          w={{ base: "71px", md: "71px" }}
          h={{ base: "66px", md: "66px" }}
          my={{ base: "20px", md: "35px" }}
        >
          <GatsbyImage
            image={shopWanaModalData.img.asset.gatsbyImageData}
            alt={shopWanaModalData.img.asset.altText || "wana logo"}
          />
          {/* <Image src={wanaLogo} alt="wana logo" /> */}
        </Flex>

        {/* TITLE */}
        <Text
          maxW={{ base: "70vw", msx: "90%" }}
          color="primary"
          fontSize={{
            base: "30px",
            md: "30px",
            mds: "35px",
            lg: "40px",
            lgs: "45px",
            xl: "50px",
          }}
          fontWeight={{ base: "900", md: "900" }}
          lineHeight={{
            base: "25px",
            md: "25px",
            mds: "30px",
            lg: "35px",
            lgs: "40px",
            xl: "45px",
          }}
          letterSpacing={{ base: "-0.38px", md: "-0.38px" }}
          textAlign="center"
          textTransform="uppercase"
          mb={{
            base: "24px",
            md: "25px",
            mds: "26px",
            lg: "27px",
            lgs: "28px",
            xl: "29px",
          }}
        >
          {shopWanaModalData.header}
        </Text>

        {/* BOLD TEXT */}
        <Text
          w={{ base: "70vw", msx: "370px" }}
          color="primary"
          fontSize={{ base: "12px", md: "15px" }}
          fontWeight={{ base: "900", md: "900" }}
          letterSpacing={{ base: "0px", md: "0px" }}
          lineHeight={{ base: "24px", md: "30px" }}
          textAlign="center"
        >
          {shopWanaModalData.boldText}
        </Text>

        {/* STORE TYPE BUTTONS */}
        <Flex
          w={{ base: "260px", msx: "316px" }}
          justifyContent={{ base: "space-evenly" }}
          alignItems="center"
          mb={{ base: "10px", md: "10px" }}
          marginRight="25px"
          marginLeft="25px"
          marginTop="25px"
          marginBottom="25px"
        >
          {/* MEDICAL */}
          <CTAButton
            onClick={() => {
              closeModal();
              navigate(
                `${shopWanaModalData.medBtn.ctaRoute.route}?store_type=${shopWanaModalData.medBtn.text}`
              );
            }}
            width={{ base: "100px", ssm: "125px", lg: "144px" }}
            height={{ base: "40px", lg: "48px" }}
            fontSize={{ base: "10px", md: "13px" }}
            fontWeight="900"
            bgColor={
              shopWanaModalData.medBtn.bgColor
                ? shopWanaModalData.medBtn.bgColor.color
                : "transparent"
            }
            color={
              shopWanaModalData.medBtn.textColor
                ? shopWanaModalData.medBtn.textColor.color
                : "primary"
            }
            border={"2px"}
            borderColor={
              shopWanaModalData.medBtn.textColor
                ? shopWanaModalData.medBtn.textColor.color
                : "primary"
            }
            _hover={{ bg: "#272158", color: "white" }}
            ctaText={shopWanaModalData.medBtn.text}
          />

          {/* RECREATIONAL */}
          <CTAButton
            onClick={() => {
              closeModal();
              navigate(
                `${shopWanaModalData.medBtn.ctaRoute.route}?store_type=${shopWanaModalData.recBtn.text}`
              );
            }}
            width={{ base: "100px", ssm: "125px", lg: "144px" }}
            height={{ base: "40px", lg: "48px" }}
            fontSize={{ base: "10px", md: "13px" }}
            fontWeight="900"
            bgColor={
              shopWanaModalData.recBtn.bgColor
                ? shopWanaModalData.recBtn.bgColor.color
                : "transparent"
            }
            color={
              shopWanaModalData.recBtn.textColor
                ? shopWanaModalData.recBtn.textColor.color
                : "primary"
            }
            border={"2px"}
            borderColor={
              shopWanaModalData.recBtn.textColor
                ? shopWanaModalData.recBtn.textColor.color
                : "primary"
            }
            _hover={{ bg: "#272158", color: "white" }}
            ctaText={shopWanaModalData.recBtn.text}
          />
        </Flex>

        {/* PRICE TEXT */}
        <Text
          w={{ base: "70vw", ms: "365px", msx: "370px" }}
          color="primary"
          fontSize={{ base: "12px", md: "15px" }}
          fontWeight={{ base: "600", md: "600" }}
          letterSpacing={{ base: "0px", md: "0px" }}
          lineHeight={{ base: "24px", md: "30px" }}
          textAlign="center"
          mb={{ base: "12px", md: "29px" }}
        >
          {shopWanaModalData.mainText}
        </Text>

        {/* CLOSE MODAL */}
        <Box
          role="button"
          onClick={() => closeModal()}
          my={{ base: "12px", md: "17px" }}
        >
          <Text
            color="primary"
            fontSize={{ base: "14px", md: "14px" }}
            fontWeight="600"
            letterSpacing={{ base: "-0.16px", md: "-0.16px" }}
            lineHeight={{ base: "1", md: "1" }}
            borderBottom="1px"
            borderColor="primary"
          >
            {"close"}
          </Text>
        </Box>
      </Flex>
    </Modal>
  );
}

export default BuyNowOverlay;
