import * as React from "react";
import { Box, Text, Flex, Collapse, Fade } from "@chakra-ui/react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CTAButton from "../../components/CTAButton";
import LinkWrapper from "../LinkWrapper";
import SocialMedia from "../SocialMedia";

/* IMPORT APP CONTEXT */
import { AppContext } from "../../context";

function Menu(props) {
  const appContext = React.useContext(AppContext);

  const { showMenu, setShowMenu } = props;

  const data = useStaticQuery(graphql`
    query MyQuery {
      allSanityMenu {
        nodes {
          topContainerColor {
            color
          }
          bottomContainerColor {
            color
          }
          products {
            accentHeader {
              headerText
              textColor {
                color
              }
            }
            mainHeader {
              headerText
              textColor {
                color
              }
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
            image {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          menuLinksSets {
            linksSetHeader
            headerTextColor {
              color
            }
            linksTextColor {
              color
            }
            links {
              ctaRoute {
                routeName
                slug {
                  current
                }
                route
              }
              linkToPage {
                slug {
                  current
                }
              }
              text
            }
          }
          routeToShopAllPage {
            route
          }
        }
      }
    }
  `);

  const menuData = data.allSanityMenu.nodes[0];

  return (
    <Box
      w="100%"
      display={showMenu ? "block" : "none"}
      position="absolute"
      // top={{base:'53px', md:'76px', lg:'96px'}}
      boxShadow="lg"
      zIndex="4"
      style={{ transition: "transform 0.6s cubic-bezier(0.61, 1, 0.88, 1)" }}
      bgColor={menuData.topContainerColor?.color || "primaryMenu"}
      // onClick={() => setShowMenu(false)}
    >
      {/* TOP CONTAINER */}
      <Box
        id="menu-topContainer"
        w={{ base: "100%" }}
        bgColor={menuData.topContainerColor?.color || "primaryMenu"}
        pt={{ base: "82px", md: "9.96vw", lg: "102px" }}
        pb={{ base: "27px", md: "7.13vw", lg: "73px" }}
      >
        {/* PRODUCTS */}
        <Box
          w="100%"
          display="grid"
          gridTemplateColumns={{
            base: "repeat(1, 1fr)",
            msx: "repeat(5, 1fr)",
          }}
          gridRowGap={{ base: "20px", md: "0" }}
          className="menu-grid-box"
        >
          {menuData.products.slice(0, 6).map((product, indx) => {
            return product.ctaRoute.routeName === "SHOP CBD" ? (
              <div className="cbd-menu-container" key={indx}>
                <div className="cbd-menu-product">
                  <MenuProduct
                    accentHeader={
                      product.accentHeader || {
                        headerText: "none",
                        textColor: { color: "transparent" },
                      }
                    }
                    mainHeader={product.mainHeader}
                    ctaRoute={product.ctaRoute}
                    image={product.image}
                  />
                </div>
                <div className="cbd-modal">
                  <CTAButton
                    onClick={appContext.actions.shopCBDModalControl}
                    width={{ base: "100%", ssm: "100%", lg: "100%" }}
                    height={{ base: "100%", lg: "100%" }}
                    _hover={{ bg: "transparent", color: "transparent" }}
                  />
                </div>
              </div>
            ) : (
              <MenuProduct
                key={indx}
                accentHeader={
                  product.accentHeader || {
                    headerText: "none",
                    textColor: { color: "transparent" },
                  }
                }
                mainHeader={product.mainHeader}
                linkToPage={product.linkToPage}
                ctaRoute={product.ctaRoute}
                image={product.image}
              />
            );
          })}
        </Box>

        {/* CTA */}
        <Box
          display={{ base: "none", msx: "block" }}
          w={{ msx: "130px", xsl: "140px", msl: "150px", ml: "160px" }}
          h={{ msx: "48px" }}
          m="auto"
          mt={{ msx: "53px" }}
        >
          <CTAButton
            href={menuData.routeToShopAllPage.route || "/"}
            fontSize={{ base: "13px", xsl: "15px", msl: "17px", ml: "19px" }}
            bgColor={"transparent"}
            color={"white"}
            width="100%"
            height="100%"
            _hover={{
              bg: "white",
              color: menuData.topContainerColor?.color || "primaryMenu",
            }}
            ctaText={"shop all"}
            border={"1px"}
            borderColor={"white"}
          />
        </Box>
      </Box>

      {/* BOTTOM CONTAINER */}
      <Box
        id="menu-bottomContainer"
        w="100%"
        bgColor={menuData.bottomContainerColor?.color || "primaryDarker"}
        pt={{ base: "12px", md: "7.78vw", xl: "112px" }}
        pb={{ base: "30px", md: "8.54vw", xl: "123px" }}
        px={{
          base: "50px",
          md: "0",
          mds: "8vw",
          lg: "8.5vw",
          lgs: "9.72vw",
          xl: "140px",
          xxl: "9.72vw",
        }}
        zIndex="4"
      >
        <Box
          w="100%"
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{
            base: "space-between",
            md: "space-evenly",
            mds: "space-between",
          }}
          alignItems={{ base: "center", md: "flex-start" }}
        >
          {menuData.menuLinksSets.map((linksSet, indx) => {
            return (
              <LinksSet
                key={indx}
                itemIndex={indx}
                linksSetHeader={linksSet.linksSetHeader}
                headerTextColor={linksSet.headerTextColor}
                linksTextColor={linksSet.linksTextColor}
                links={linksSet.links}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

type menuProductProps = {
  accentHeader: { headerText: string; textColor: { color: string } };
  mainHeader: { headerText: string; textColor: { color: string } };
  linkToPage: { slug: { current: string } };
  image: { asset: {} };
};

const MenuProduct = (props: menuProductProps) => {
  const { accentHeader, mainHeader, linkToPage, image, ctaRoute } = props;
  const productImg = getImage(image.asset);

  return (
    <LinkWrapper href={ctaRoute.route || ""}>
      <Flex
        w={{ base: "55%", msx: "100%", ms: "50%", md: "100%" }}
        // h={{msx:'100%'}}
        direction={{ base: "row", msx: "column" }}
        m="auto"
        justifyContent={{
          base: "space-between",
          ms: "space-around",
          md: "flex-end",
        }}
        alignItems={{ base: "center" }}
        // border={mainHeader.headerText.toLowerCase() === 'tinctures' ? "1px" : '0'}
      >
        <Box
          maxW={
            mainHeader.headerText.toLowerCase() === "tinctures"
              ? { base: "10%", ms: "10%", msx: "14%", md: "15%", xl: "44px" }
              : { base: "27%", ms: "24%", msx: "28%", md: "35%", xl: "101px" }
          }
          h={{ base: "auto" }}
          // border={mainHeader.headerText.toLowerCase() === 'tinctures' ? "1px" : '0'}
          ml={
            mainHeader.headerText.toLowerCase() === "tinctures"
              ? { base: "20px", ms: "10px", md: "0" }
              : "0"
          }
        >
          <GatsbyImage
            image={productImg}
            alt={image.asset.altText || `wana ${accentHeader.headerText} image`}
          />
        </Box>

        <Box
          w={{ base: "63%", ms: "56%", md: "85%", lg: "70%" }}
          mt={{ md: "10px" }}
        >
          {/* ACCENT HEADER */}
          {/* <Text
            display={{
              base: `${accentHeader.headerText === "none" ? "none" : "block"}`,
              msx: "block",
            }}
            color={accentHeader.textColor?.color || "blue"}
            fontSize={{
              base: "14px",
              msx: "11px",
              md: "14px",
              lg: "15px",
              xsl: "17px",
              msl: "19px",
              ml: "21px",
            }}
            textTransform="uppercase"
            textAlign={{ msx: "center" }}
          >
            {accentHeader.headerText}
          </Text> */}

          {/* MAIN HEADER */}
          <Text
            color={mainHeader.textColor?.color || "white"}
            fontSize={{
              base: "26px",
              msx: "14.8px",
              md: "26px",
              xl: "40px",
              xsl: "42px",
              msl: "44px",
              ml: "46px",
            }}
            textTransform={
              mainHeader.headerText.length < 4 ? "uppercase" : "capitalize"
            }
            textAlign={{ msx: "center" }}
          >
            {mainHeader.headerText}
          </Text>
        </Box>
      </Flex>
    </LinkWrapper>
  );
};

type linksSetProps = {
  itemIndex: number;
  linksSetHeader: string;
  headerTextColor: { color: string };
  linksTextColor: { color: string };
  links: {
    text: string;
    linkToPage: { slug: { current: string } };
  }[];
};

const LinksSet = (props: linksSetProps) => {
  const { itemIndex, linksSetHeader, headerTextColor, linksTextColor, links } =
    props;
  const [show, setShow] = React.useState(false);

  return (
    <Box
      w={{
        base: "max-content",
        md: "230px",
        mds: itemIndex === 1 ? "max-content" : "225px",
        lg: itemIndex === 1 ? "max-content" : "240px",
      }}
      // border="1px"
      // borderColor="white"
    >
      <Text
        color={headerTextColor?.color || "white"}
        fontSize={{
          base: "15px",
          md: "15px",
          xsl: "17px",
          msl: "19px",
          ml: "21px",
        }}
        fontWeight="800"
        letterSpacing={{ base: "3.75px", md: "3.75px" }}
        lineHeight={{ base: "20px", md: "20px" }}
        textTransform="uppercase"
        textAlign={{ base: "center", md: "center", mds: "left" }}
        mt={{ base: "30px", md: "0" }}
        mb={{ base: "0", md: "10px", xxl: "0.69vw" }}
        onClick={() => setShow(!show)}
        id="menu-linksSetHeader"
      >
        {linksSetHeader}
      </Text>

      {/* COLLAPSABLE LINKS */}
      <Box display={{ base: "block", md: "none" }}>
        <Collapse startingHeight={0} in={show}>
          <Links linksTextColor={linksTextColor} links={links} />

          {itemIndex === 2 && (
            <SocialMedia
              items={[
                "facebook",
                "twitter",
                "instagram",
                "youtube",
                "linkedin",
              ]}
              color={"white"}
              size={20}
              spacing={{ base: "25px", md: "19px", lg: "25px" }}
            >
              <Text
                color={linksTextColor?.color || "white"}
                // textTransform="capitalize"
                textAlign={{ base: "center", md: "center", mds: "left" }}
                fontSize={{
                  base: "20",
                  lgs: "30px",
                  xsl: "32px",
                  msl: "34px",
                  ml: "36px",
                }}
                fontWeight="600"
                mb={{ base: "10px" }}
              >
                {" "}
              </Text>
            </SocialMedia>
          )}
        </Collapse>
      </Box>

      {/* NON COLLAPSABLLE LINKS */}
      <Box display={{ base: "none", md: "block" }}>
        <Links linksTextColor={linksTextColor} links={links} />

        {itemIndex === 2 && (
          <SocialMedia
            items={["facebook", "twitter", "instagram", "youtube", "linkedin"]}
            color={"white"}
            size={20}
            spacing={{ base: "25px", md: "19px", lg: "25px" }}
            inMenu={true}
          >
            <Text
              color={linksTextColor?.color || "white"}
              // textTransform="capitalize"
              textAlign={{ base: "center", md: "center", mds: "left" }}
              fontSize={{
                base: "20",
                lgs: "30px",
                xsl: "32px",
                msl: "34px",
                ml: "36px",
              }}
              fontWeight="600"
              mb={{ base: "20px", md: "10px" }}
            >
              {" "}
            </Text>
          </SocialMedia>
        )}
      </Box>
    </Box>
  );
};

type linksProps = {
  linksTextColor: { color: string };
  links: {
    text: string;
    linkToPage: { slug: { current: string } };
  }[];
};

const Links = (props: linksProps) => {
  const { linksTextColor, links } = props;

  return (
    <Box>
      {links.map((link, indx) => {
        return (
          <LinkWrapper
            key={indx}
            href={link.ctaRoute ? link.ctaRoute.route : "/"}
          >
            <Text
              color={linksTextColor?.color || "white"}
              fontSize={{
                base: "20",
                lgs: "30px",
                xsl: "32px",
                msl: "34px",
                ml: "36px",
              }}
              fontWeight="600"
              letterSpacing={{ base: "0", md: "0" }}
              lineHeight={{ base: "35px", md: "50px" }}
              // textTransform="capitalize"
              textAlign={{ base: "center", md: "center", mds: "left" }}
            >
              {link.text}
            </Text>
          </LinkWrapper>
        );
      })}
    </Box>
  );
};

export default Menu;
