import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Text, Flex, Image } from "@chakra-ui/react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import wanaLogo from '../../images/wanaLogo.svg';

const isBrowser = typeof window !== "undefined"

function AgeVerification(props) {
  const { setAgeCookie } = props;

  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityAgeVerificationCheck {
        nodes {
          bgImage {
            asset {
              gatsbyImageData
            }
          }
          accentHeader
          mainHeader
          details
          buttonText
          img {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  
  const ageVerificationData = data.allSanityAgeVerificationCheck.nodes[0];

  return (
    <Box
      w="100vw"
      h="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {/* BG IMAGE */}
      <Box
        w="100%"
        h="100%"
        position="absolute"
        top="0"
        bgImage={ageVerificationData.bgImage.asset.gatsbyImageData.images.fallback.src}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
        opacity="0.5"
      >

      </Box>

      {/* CONTENT */}
      <Flex
        w={{base:'100%', md:'466px'}}
        maxH={{base:'577px', md:'577px'}}
        direction="column"
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        zIndex="overlay"
        style={{boxShadow:'0 30px 40px -20px #131029'}}
      >
        {/* IMAGE */}
        <Flex
          w={{base:'95px', md:'95px'}}
          h={{base:'88px', md:'88px'}}
          mt={{base:'25px', md:'46px'}}
          mb={{base:'25px', md:'51px'}}
        >
          <GatsbyImage image={ageVerificationData.img.asset.gatsbyImageData} alt={ageVerificationData.img.asset.altText || 'wana logo'} />
          {/* <Image src={wanaLogo} alt="wana logo" /> */}
        </Flex>

        {/* ACCENT HEADER */}
        <Text
          maxW={{base:'84%', md:'350px'}}
          as="h2"
          color="primary"
          fontSize={{base:'16px', md:'16px'}}
          fontWeight="800"
          letterSpacing={{base:'4.8px', md:'4.8px'}}
          lineHeight={{base:'20px', md:'20px'}}
          textAlign="center"
          textTransform="uppercase"
          pb={{base:'9px', md:'9px'}}
        >
          {ageVerificationData.accentHeader}
        </Text>

        {/* MAIN HEADER */}
        <Text
          maxW={{base:'84%', md:'404px'}}
          as="h1"
          color="primary"
          fontSize={{base:'50px', md:'50px'}}
          fontWeight="900"
          letterSpacing={{base:'-0.38px', md:'-0.38px'}}
          lineHeight={{base:'45px', md:'45px'}}
          textAlign="center"
          textTransform="uppercase"
          px={{base:'10px', md:'10px'}}
          pb={{base:'10px', md:'50px'}}
        >
          {ageVerificationData.mainHeader}
        </Text>

        {/* DESCRIPTION */}
        <Text
          maxW={{base:'84%', md:'283px'}}
          color="primary"
          fontSize={{base:'14px', md:'14px'}}
          fontWeight="600"
          letterSpacing={{base:'-0.16px', md:'-0.16px'}}
          lineHeight={{base:'28px', md:'28px'}}
          textAlign="center"
          pb={{base:'15px', md:'35px'}}
        >
          {ageVerificationData.details}
        </Text>

        {/* BTN */}
        <Box 
          width={'152px'} 
          height={{base:'48px', lg:'48px'}}
          mx={{base:'10px', md:'10px'}}
          mb={{base:'10px', md:'10px'}}
          bgColor={'greenMantis'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          role="button"
          onClick={setAgeCookie}
          _hover={{ bg: 'primary' }}
        >
          <Text
            fontSize={{base:'13px', md:'13px'}}
            fontWeight='900'
            lineHeight="1"
            color={'white'}
            textTransform="uppercase"
          >
            {ageVerificationData.buttonText}
          </Text>
        </Box>

        {/* LEAVE */}
        <Box
          role="button"
          onClick={() => isBrowser ? window.history.back() : null}
        >
          <Text
            color="primary"
            fontSize={{base:'14px', md:'14px'}}
            fontWeight="600"
            letterSpacing={{base:'-0.16px', md:'-0.16px'}}
            lineHeight={{base:'1', md:'1'}}
            mb={{base:'25px', md:'45px'}}
            borderBottom="1px"
            borderColor="primary"
          >
            {'leave'}
          </Text>
        </Box>
      </Flex>

    </Box>
  );
}

export default AgeVerification;
