import * as React from "react";
import { Button, Box, Text } from "@chakra-ui/react";
import LinkWrapper from "../LinkWrapper";

function CTAButton(props) {
  const {
    href,
    fontSize,
    borderRadius,
    border,
    borderColor,
    bgColor,
    color,
    width,
    height,
    fontWeight,
    letterSpacing,
    lineHeight,
    _hover,
    ctaText,
    onClick,
    mx,
    onMouseEnter,
    onMouseLeave,
  } = props;

  // !href ? console.log('href: ', href, ctaText) : null;

  return (
    <>
      {href && (
        <LinkWrapper href={href}>
          <Box
            width="150px"
            height={height || { sm: "48px" }}
            bgColor={bgColor || "transparent"}
            color={color || "primary"}
            borderRadius={borderRadius || "0"}
            border={border || "0"}
            borderColor={borderColor || "primary"}
            _hover={_hover || { bg: "primary", color: "white" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            mx={mx || "0"}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Text
              fontSize={fontSize || { sm: "13px" }}
              fontWeight={fontWeight || "800"}
              letterSpacing={letterSpacing || { sm: "1.38px", md: "1.5px" }}
              lineHeight="1"
              textTransform="uppercase"
              textAlign="center"
              pb="0"
              width="150px"
              // border="1px"
            >
              {ctaText}
            </Text>
          </Box>
        </LinkWrapper>
      )}

      {!href && (
        <Box
          onClick={onClick}
          width="150px"
          height={height || { sm: "48px" }}
          bgColor={bgColor || "transparent"}
          color={color || "primary"}
          borderRadius={borderRadius || "0"}
          border={border || "0"}
          borderColor={borderColor || "primary"}
          _hover={_hover || { bg: "primary", color: "white" }}
          role="button"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mx={mx || "0"}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Text
            fontSize={fontSize || { sm: "13px" }}
            fontWeight={fontWeight || "800"}
            letterSpacing={letterSpacing || { sm: "1.38px", md: "1.5px" }}
            lineHeight="1"
            textTransform="uppercase"
            textAlign="center"
            pb="0"
            paddingLeft="5px"
            width="150px"
            // border="1px"
          >
            {ctaText}
          </Text>
        </Box>
      )}
    </>
  );
}

export default CTAButton;
