import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Text, Image } from '@chakra-ui/react';
import LinkWrapper from '../LinkWrapper';
import CTAButton from '../CTAButton';
import SocialMedia from '../SocialMedia';

/* IMPORT APP CONTEXT */
import { AppContext } from '../../context';

import mapImg from '../../images/map.png';

function Footer(props) {
  const { hideFooter } = props;
  const appContext = React.useContext(AppContext);

  const data = useStaticQuery(graphql`
    query {
      allSanityFooter {
        nodes {
          _id
          logo {
            image {
              asset {
                url
              }
            }
            altText
          }
          shopLinks {
            text
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          aboutLinks {
            text
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          educationLinks {
            text
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          bottomLinks {
            text
            specialCharacter
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          routeToFindPage {
            route
          }
        }
      }
    }
  `);

  const footerData = data.allSanityFooter.nodes[0];

  return (
    <Box
      maxW={{ base: '83%', md: '95%', lg: '84%', xl: '1200px' }}
      h={{ base: '475px', md: '219px' }}
      m={{ base: 'auto' }}
      mt={{ base: '58px', md: '102px' }}
      mb={{ base: '10px', md: '2px' }}
      display={hideFooter ? 'none' : 'grid'}
      gridTemplateColumns={{ base: '100%', md: '10% 63% 27%', lg: '15.5% auto 26%', xl: '15.5% auto 26%' }}
      gridTemplateRows={{ base: '41.5% 48% 12.5%', md: '100%' }}
    >
      {/* LOGO */}
      <Box
        w={{ base: 'auto', md: '100%' }}
        h="100%"
        m={{ base: 'auto', md: '0' }}
        display="grid"
        gridTemplateRows={{ base: '78% 11.5% 10.5%', md: '70% 18% 12%' }}
      >
        {/* IMAGE */}
        <Box
          w="100%"
          h="100%"
        >
          <LinkWrapper href={'/'}>
            <Image
              w="100%"
              h="100%"
              src={footerData.logo.image.asset.url}
              alt={footerData.logo.altText}
            />
          </LinkWrapper>
        </Box>

        {/* SPACE */}
        <Box />

        {/* SOCIAL */}
        <Box
          w="100%"
          h="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SocialMedia
            withBG
            color={"#266f54"}
            size={20}
            spacing={{ base: '15px', md: '10px', lg: '15px' }}
            items={['linkedin', 'youtube', 'twitter', 'instagram', 'facebook']}
          />
        </Box>
      </Box>

      {/* LINKS */}
      <Box
        w="100%"
        display={{ base: 'none', md: 'grid' }}
        gridTemplateRows={{ md: '20% 54% 16.5% 9.5%' }}
      >
        {/* SPACE */}
        <Box></Box>

        {/* TOP LINKS */}
        <Box
          w="100%"
          display="grid"
          gridTemplateColumns={{ md: '21.5% 17% 1% 17% 4% 17% 3.5% 17% 2%', lg: '16.5% 17% 1% 17% 4% 17% 3.5% 17% 7%' }}
        >
          {/* SPACE */}
          <Box></Box>

          {/* SHOP1 */}
          <Box>
            <LinksList header={'Shop'} links={footerData.shopLinks.slice(0, 3)} showHeader={true} />
          </Box>

          {/* SPACE */}
          <Box></Box>

          {/* SHOP2 */}
          <Box>
            <LinksList header={'Shop'} links={footerData.shopLinks.slice(3)} showHeader={false} />
          </Box>

          {/* SPACE */}
          <Box></Box>

          {/* ABOUT */}
          <Box>
            <LinksList header={'About'} links={footerData.aboutLinks} showHeader={true} />
          </Box>

          {/* SPACE */}
          <Box></Box>

          {/* EDUCATION */}
          <Box>
            <LinksList header={'Education'} links={footerData.educationLinks} showHeader={true} />
          </Box>

          {/* SPACE */}
          <Box></Box>
        </Box>
      </Box>

      {/* FIND */}
      <Box
        w="100%"
        display="grid"
        gridTemplateRows={{ base: '21% 79%', md: '23.5% 76.5%' }}
      >
        {/* SPACE */}
        <Box></Box>

        {/* MAP */}
        <Box
          w={{ base: '296px', ms: '355px', md: '100%' }}
          display="grid"
          gridTemplateRows={{ base: '13% 38% 28.5% 20.5%', md: '11.5% 39.5% 29% 20%' }}
          bgImage={mapImg}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="cover"
          mx={{ base: 'auto', md: '0' }}
        >
          {/* SPACE */}
          <Box></Box>

          {/* HEADER */}
          <Box
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              w={{ base: '76.5%' }}
            >
              <Text
                fontSize={{ base: '40px', md: '30px', lg: '30px', xl: '40px' }}
                fontWeight="800"
                lineHeight={{ base: '65px' }}
                letterSpacing={{ base: '-0.5px' }}
                color="primary"
                textAlign="center"
              >
                Find a Store
              </Text>
            </Box>
          </Box>

          {/* BUTTON */}
          <Box
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              w={{ base: '209px', md: '74%', lg: '66%', lgs: '57%', xl: '196px' }}
            >
              <CTAButton
                href={footerData.routeToFindPage.route || '/find/'}
                fontSize={{ base: '13px' }}
                bgColor={'primaryDarker'}
                color={'white'}
                width={{ base: '100%' }}
                height={{ base: '48px', lg: '48px' }}
                _hover={{ bg: 'blue', color: 'white' }}
                ctaText={'find now'}
              />
            </Box>
          </Box>

          {/* SPACE */}
          <Box></Box>
        </Box>
      </Box>

      {/* BOTTOM LINKS */}
      <Box 
        display={{ base: 'flex' }}
        gridColumn={{ base: '1', md: '1 / 4' }}
        alignSelf='center'
        justifySelf='center'
        >
        <Box></Box>
        {buildBottomLinkBox(footerData)}
      </Box>
      
    </Box>
  );
}

type linkKistProps = {
  header: string,
  links: {
    text: string,
    linkToPage: { slug: { current: string } },
  }[],
  showHeader: boolean,
};

const LinksList = (props: linkKistProps) => {
  const { header, links, showHeader } = props;

  return (
    <Box
      w="100%"
      h="100%"
      display="grid"
      gridTemplateRows={{ md: '23% 77%' }}
    >
      {/* HEADER */}
      <Box>
        <Text
          color={showHeader ? '#B0ACCE' : 'transparent'}
          fontSize={{ md: '13px', lg: '15px', xl: '15px' }}
          fontWeight="900"
          letterSpacing="0"
          lineHeight="30px"
        // textTransform="capitalize"
        >
          {header}
        </Text>
      </Box>

      {/* LINKS */}
      <Box>
        {links.map((link, indx) => {
          return (
            <LinkWrapper key={indx} href={link.ctaRoute ? link.ctaRoute.route : '/'}>
              <Box>
                <Text
                  color="primary"
                  fontSize={{ md: '12px', lg: '15px', xl: '15px' }}
                  letterSpacing={{ md: '0' }}
                  lineHeight={{ md: '30px' }}
                // textTransform="capitalize"
                >
                  {link.text}
                </Text>
              </Box>
            </LinkWrapper>
          );
        })}
      </Box>
    </Box>
  );
};

type bottomLinksProps = {
  bottomLinks: {
    text: string,
    ctaRoute: {
      route,
      routeName: string
      slug: {
        current: string
      }
    }
  }[];
}

const BottomLinks = (props: bottomLinksProps) => {
  const { bottomLinks } = props;
  const justifyContent = ['center', 'space-evenly', 'space-between',];
  // const btml = [...bottomLinks, ...bottomLinks, ...bottomLinks];
  return (
    <Box
      w={{ base: '296px', ms: '355px', md: '100%' }}
      h="100%"
      display="flex"
      justifyContent={justifyContent[bottomLinks.length - 1]}
      alignItems="center"
      mx={{ base: 'auto', md: '0' }}
    >
      {/* LINKS */}
      {bottomLinks.map((link, indx) => {
        return (
          <LinkWrapper href={link.ctaRoute ? link.ctaRoute.route : '/'} key={indx}>
            <Box>
              <Text
                  margin={{ base: '8px 8px 8px 8px', md: '0px 16px 8px 16px' }}
                  whiteSpace='nowrap'
                  color="primary"
                  fontSize={{ md: '12px', lg: '15px', xl: '15px' }}
                  letterSpacing={{ md: '0' }}
                  lineHeight={{ md: '30px' }}
                  >
                {link.text}
              </Text>
            </Box>
          </LinkWrapper>
        );
      })}
    </Box>
  );
};

function buildBottomLinkBox(footerData) {
  let links = footerData.bottomLinks.filter(l => !l.text.startsWith('©'));
  return (
    <Box display="flex" flexDir="column" alignItems="center" justifyContent="center">
      <BottomLinks bottomLinks={links} />
      {buildCopyrightLink(footerData)}
    </Box>
  );
}

function buildCopyrightLink(footerData: bottomLinksProps) {
  let link = footerData.bottomLinks.find(l => l.text.startsWith('©'));
  return <LinkWrapper
    href={link?.ctaRoute.route}>
    <Text
      color="#8D89A3"
      fontSize={{ base: '13px', md: '12px', lg: '15px', xl: '15px' }}
      lineHeight={{ base: '1' }}
      letterSpacing={{ base: '0' }}
      textAlign={{ base: 'center' }}
    >{link?.text}</Text>
  </LinkWrapper>
}

export default Footer;