import * as React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useStaticQuery, graphql, Link } from "gatsby";

/* COMPONENTS */
import CTAButton from "../CTAButton";

/* IMPORT APP CONTEXT */
import { AppContext } from "../../context";

function HeaderStickyButtons(props) {
  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityHeader {
        nodes {
          ctaLeft {
            text
            linkToPage {
              slug {
                current
              }
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            bgColor {
              color
            }
            textColor {
              color
            }
          }
          ctaRight {
            text
            linkToPage {
              slug {
                current
              }
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            bgColor {
              color
            }
            textColor {
              color
            }
          }
        }
      }
    }
  `);

  /* HEADER DATA - SANITY */
  const headerData = data.allSanityHeader.nodes[0];

  const appContext = React.useContext(AppContext);

  return (
    <Box
      w="100%"
      h="48px"
      display="grid"
    >
      {/* BUY NOW */}
      {/* <Box width="150px">
        <CTAButton
          onClick={appContext.actions.buyNowModalControl}
          fontSize={"13px"}
          fontWeight={"800"}
          letterSpacing={"1.5px"}
          lineHeight={"21px"}
          bgColor={headerData.ctaLeft.bgColor?.color || "white"}
          color={headerData.ctaLeft.textColor?.color || "primary"}
          width={"100%"}
          height={"100%"}
          _hover={{
            bg: headerData.ctaLeft.textColor?.color || "primary",
            color: headerData.ctaLeft.bgColor?.color || "white",
          }}
          ctaText={headerData.ctaLeft.text}
          border={"1px"}
          borderColor={headerData.ctaLeft.textColor?.color || "primary"}
        />
      </Box> */}

      {/* FIND */}
      <Box marginLeft={`auto`}  width="150px">
        <CTAButton
          href={headerData.ctaRight.ctaRoute.route}
          fontSize={"13px"}
          fontWeight={"800"}
          letterSpacing={"1.5px"}
          lineHeight={"21px"}
          bgColor={headerData.ctaRight.bgColor?.color || "white"}
          color={headerData.ctaRight.textColor?.color || "primary"}
          width={"100%"}
          height={"100%"}
          _hover={{
            bg: headerData.ctaRight.textColor?.color || "primary",
            color: headerData.ctaRight.bgColor?.color || "white",
          }}
          ctaText={headerData.ctaRight.text}
          border={"1px"}
          borderColor={headerData.ctaRight.textColor?.color || "primary"}
        />
      </Box>
    </Box>
  );
}

export default HeaderStickyButtons;
