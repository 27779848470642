import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Flex, Text } from '@chakra-ui/react';
import { RiMenu2Line, RiCloseFill} from 'react-icons/ri';
import useWindowSize from '../../utitlities/useWindowSize';

/* COMPONENTS */
import CTAButton from '../CTAButton';
import Menu from '../Menu';

/* IMPORT APP CONTEXT */
import { AppContext } from '../../context';

function Header(props) {
  const { showMenu, setShowMenu } = props;

  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityHeader {
        nodes {
          _id
          headerText {
            headerText
            textColor {
              color
            }
          }
          logoImg {
            altText
            image {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          ctaLeft {
            text
            linkToPage {
              slug {
                current
              }
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            bgColor {
              color
            }
            textColor {
              color
            }
          }
          ctaRight {
            text
            linkToPage {
              slug {
                current
              }
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            bgColor {
              color
            }
            textColor {
              color
            }
          }
        }
      }
    }
  `);
  
  /* HEADER DATA - SANITY */
  const headerData = data.allSanityHeader.nodes[0];

  const logo = getImage(headerData.logoImg.image.asset);

  const appContext = React.useContext(AppContext);

  const windowSize = useWindowSize();

  return (
    <Box
      w="100%"
      position="relative"
      bgColor="white"
      ref={appContext.headerRef}
      zIndex="5"
    >
      {/* HEADER CONTENT */}
      <Box
        w="100%"
        h={{base:'53px', md:'76px', lg:'96px'}} //960px --> 96px
        display="grid"
        gridTemplateColumns="35% 30% 35%" //35% 30% 35% repeat(3, 1fr)
        position='relative'
        zIndex="5"
        style={{boxShadow:'0px 5px 10px -10px #131029'}}
      > 
        {/* HEADER TEXT */}
        <Flex
          h={{base:'53px', md:'76px', lg:'96px'}} 
          justifyContent="center"
          alignItems="center"
        >
          <Text
            display={{base:'none', md:'block'}} 
            color={headerData?.headerText?.textColor ? headerData?.headerText?.textColor.color : 'primaryDarker'} 
            fontSize={{md:'10px', lg:'14px', xl:'18px'}} 
            fontWeight="bold" 
            letterSpacing="1px" 
            lineHeight="20px"
            textAlign="left"
            px={{md:'2vw', lg:'1.5vw', xl:'20px'}}
          >
            {headerData?.headerText?.headerText}
          </Text>
        </Flex>

        {/* HEADER LOGO */}
        <Box
          position="relative"
        >
          <Box
            w="100%"
            h="100%"
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              bgColor="white" 
              w={{base:'128px', md:'146px', lg:'164px'}} 
              h={{base:'128px', md:'146px', lg:'164px'}} 
              borderRadius="full" 
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              {/* LOGO IMAGE */}
              <Box
                w={{base:'58%'}}
                h='auto'
                mt={{base:'25px', md:'20px', lg:'18px', xl:'19px'}}
                zIndex="overlay"
              >
                <Link to={'/'}>
                  <GatsbyImage
                    image={logo}
                    alt={headerData.logoImg.altText}
                  />
                </Link>
              </Box>

              <Box
                w="100%"
                h="50%"
                bgColor="white"
                position="absolute"
                bottom="0"
                borderBottomRadius="full"
                style={{boxShadow:'0px 5px 10px -10px #131029'}}
              >

              </Box>
            </Flex>
          </Box>

          <Box
            w="100%"
            h="50px"
            position="absolute"
            bottom="0"
            bgColor="white"
          >

          </Box>
        </Box>

        {/* HEADER OPTIONS */}
        <Box 
          w="100%"
          h={{base:'53px', md:'76px', lg:'96px'}}
        >
          {/* CTAs AND MENU TRIGGER */}
          <Flex 
            h="100%"
            justifyContent="flex-end" 
          >

            {/* CTAs */}
            <Box 
              display={{base:'none', md:'block', lg:'block'}} 
              m="auto" 
              mr={{lg:'26px'}}
            >
              <Flex>
                                
              <CTAButton
                  href={headerData.ctaRight.ctaRoute.route}
                  fontSize={{md:'10px', lg:'13px'}}
                  bgColor={headerData.ctaRight.bgColor?.color || 'white'}
                  color={headerData.ctaRight.textColor?.color || 'primary'}
                  width={{md:'68.54px', lg:'90.54px'}} 
                  height={{md:'40px', lg:'48px'}}
                  _hover={{bg: headerData.ctaRight.textColor?.color || 'primary', color: headerData.ctaRight.bgColor?.color || 'white'}}
                  ctaText={headerData.ctaRight.text}
                  border={'1px'}
                  borderColor={headerData.ctaRight.textColor?.color || 'primary'}
                />
                <CTAButton
                  href='/hemp'
                  fontSize={{md:'10px', lg:'13px'}}
                  bgColor={headerData.ctaLeft.bgColor?.color || 'white'}
                  color={headerData.ctaLeft.textColor?.color || 'primary'}
                  width={{md:'99.42px', lg:'123.42px'}} 
                  height={{md:'40px', lg:'48px'}}
                  _hover={{bg: headerData.ctaLeft.textColor.color, color: headerData.ctaLeft.bgColor.color}}
                  ctaText={'Explore Hemp Products'}
                  border={'1px'}
                  borderColor={headerData.ctaLeft.textColor?.color || 'primary'}
                />
              </Flex>
            </Box>

            {/* MENU TRIGGER */}
            <Box 
              w={{base:'53px', md:'76px', lg:'91px'}} 
              h="100%" 
              bgColor={showMenu ? 'primaryMenu' : 'menuGreen'}
              display="flex"
              justifyContent="center"
              alignItems="center"
              role="button"
              onClick={() => setShowMenu(!showMenu)}
            >
              {
                showMenu 
                  ? <RiCloseFill style={{ color:'white', height:'50%', width:'50%'}} />
                  : <RiMenu2Line style={{ color:'white', height:'50%', width:'50%'}} />
              }
            </Box>

          </Flex>
        </Box>
      </Box>

      {/* MENU */}
      {false && windowSize.height && (
        <Box
          w="100%"
          h={{base:`${windowSize.height - 48 - 53}px`, md:'90vh'}}
          position="absolute"
          top={{base:'53px', md:'76px', lg:'96px'}}
          zIndex="5"
          overflowY="scroll"
          className="hide-scrollbar hide-scrollbar-firefox"
        >
          <Menu
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        </Box>
      )}
      
    </Box>
  );
}

export default Header;
