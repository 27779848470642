import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const AppContext = React.createContext(null);

export function Provider(props) {

  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityRoute {
        nodes {
          routeName
          slug {
            current
          }
          route
        }
      }
    }
  `);
  
  const getObjectFromArray = (arr) => {
    const obj = {};

    arr.map((item, indx) => {
      obj[item.slug.current] = item;
    });

    return obj;
  };

  /* HOME PAGE SECTIONS */
  const [homeSections, setHomeSections] = React.useState(null);

  const updateHomeSections = (sections) => {
    setHomeSections(sections);
  };

  /* BUY NOW MODAL */
  const [isBuyNowModalOpen, setIsBuyNowModalOpen] = React.useState(null);

  const buyNowModalControl = () => {
    // console.log('buyNowModalControl');
    setIsBuyNowModalOpen(!isBuyNowModalOpen);
  };
  
  /* SHOP CBD MODAL */
  const [isShopCBDModalOpen, setIsShopCBDModalOpen] = React.useState(null);

  const shopCBDModalControl = () => {
    // console.log('shopCBDModalControl');
    setIsShopCBDModalOpen(!isShopCBDModalOpen);
  };

  /* CLEAR FILTERS BUTTON */
  const [clearFiltersClicked, setClearFiltersClicked] = React.useState(false);

  const clearFiltersControl = (buttonState) => {
    // console.log('shopCBDModalControl');
    setClearFiltersClicked(buttonState);
  };

  /* POSTS DATA */
  const postsSubcategories = {};

  const [postsBySubcategories, setPostsBySubcategories] = React.useState(postsSubcategories);

  const updatePostsBySubcategories = (posts) => {
    setPostsBySubcategories(posts);
  };

  /* PRESS DATA */
  const pressSubcategories = {};
  const [pressBySubcategories, setPressBySubcategories] = React.useState(pressSubcategories);

  const updatePressBySubcategories = (posts) => {
    setPressBySubcategories(posts);
  };

  /* TOP PAGE REF */
  const pageTopRef = React.useRef(null);

  /* HEADER REF */
  const headerRef = React.useRef(null);

  /* TOP OF PRODUCT LIST REF */
  const productsListTopRef = React.useRef(null);

  /* CHECK IF MOBILE FILTERSBAR IS OPEN ? */
  const [isFiltersBarOpen, setIsFiltersBarOpen] = React.useState(false);

  const checkIfFiltersBarOpen = (openState) => {
    setIsFiltersBarOpen(openState);
  };

  /* CURRENT FILTERS SELECTED */
  const [queryFilters, setQueryFilters] = React.useState({})

  const updateQueryFilters = (filters) => {
    setQueryFilters(filters)
  }

  return (
    <AppContext.Provider value={{
      pageTopRef: pageTopRef,
      headerRef: headerRef,
      productsListTopRef: productsListTopRef,
      homeSections: homeSections,
      isBuyNowModalOpen: isBuyNowModalOpen,
      isFiltersBarOpen: isFiltersBarOpen,
      routes: getObjectFromArray(data.allSanityRoute.nodes),
      postsBySubcategories: postsBySubcategories,
      pressBySubcategories: pressBySubcategories,
      isShopCBDModalOpen: isShopCBDModalOpen,
      clearFiltersClicked: clearFiltersClicked,
      queryFilters: queryFilters,
      actions: {
        updateHomeSections: updateHomeSections,
        buyNowModalControl: buyNowModalControl,
        checkIfFiltersBarOpen: checkIfFiltersBarOpen,
        updatePostsBySubcategories: updatePostsBySubcategories,
        updatePressBySubcategories: updatePressBySubcategories,
        shopCBDModalControl: shopCBDModalControl,
        clearFiltersControl: clearFiltersControl,
        updateQueryFilters: updateQueryFilters,
      }
    }}>
      {props.children}
    </AppContext.Provider>
  );
}

export const Consumer = AppContext.Consumer;